import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Main from '../components/layout'
import Head from '../components/head'
import { Heading1, Heading3, PageSubtitle } from '../components/typography'

import WorkSVG from '../../assets/work.svg'

const WorkHeader = styled.header`
  position: relative;
  margin: 0 var(--bleed-side);
  margin-top: 16em;

  &::before {
    z-index: -1;
    content: '';
    background: var(--cream-dark);
    border-radius: 50%;
    width: 100em;
    height: 60em;
    position: absolute;
    right: 50%;
    top: -32em;
    transform: translate(40%, -50%);
  }

  @media (max-width: 60em) {
    display: flex;
    flex-direction: column;
    margin-top: 0;

    &::before {
      top: -50%;
    }
  }
`

const WorkImage = styled(WorkSVG)`
  height: auto;
  margin-top: -1.5em;
  position: absolute;
  left: 8em;
  top: -14.5em;

  @media (max-width: 60em) {
    max-width: 100%;
    margin: -1em auto 0;
    position: static;
  }
`

const WorkContent = styled.article`
  background: var(--cream-light);
  display: flex;
  flex-wrap: wrap;
  margin: 1em;

  @media (max-width: 60em) {
    margin: 1em 0 0;
  }
`

const WorkCard = styled.div`
  margin-bottom: 4em;
  width: 50%;

  @media (max-width: 60em) {
    width: 100%;
  }
`

const WorkCardLink = styled(Link)`
  height: 100%;
  color: inherit;
  font-family: var(--font-serif);
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 3em;
  margin: 0 2em;
  transition: all 0.3s ease-in;
  background: var(--cream-dark);
  box-shadow: var(--blue-light) 0.625em 0.625em 0 -0.125em;

  &:hover,
  &:focus-visible {
    cursor: pointer;
    box-shadow: var(--green) 0.313em 0.313em 0 -0.125em,
      var(--cream) 0.313em 0.313em, var(--blue-light) 0.625em 0.625em 0 -0.125em,
      var(--cream) 0.625em 0.625em,
      var(--green-light) 0.938em 0.938em 0 -0.125em,
      var(--cream) 0.938em 0.938em;
  }

  &:focus-visible {
    outline: 0.125em solid var(--green-dark);
    outline-offset: 0;
  }

  @media (max-width: 80em) {
    padding: 2em;
    margin: 0 1em;
  }

  @media (max-width: 60em) {
    margin: 0 var(--bleed-side);
  }
`

const WorkImageWrapper = styled(GatsbyImage)`
  max-width: 100%;
  margin-bottom: 2em;
  vertical-align: top;
`

function WorkIndex({ data }) {
  const { edges: posts } = data.allMdx

  const sortedPages = posts
    .filter((page) => page.node.fields.slug.includes('work'))
    .sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order)

  return (
    <Main>
      <Head title="Work" />

      <WorkHeader>
        <WorkImage />
        <Heading1>My Passion</Heading1>
        <PageSubtitle>
          I bridge the gap between people and technology. Interfaces should
          be intuitive to users like my mom and browsing should not be a
          frustrating experience.
        </PageSubtitle>
      </WorkHeader>

      <WorkContent>
        {sortedPages.map(({ node: post }) => (
          <WorkCard key={post.id}>
            <WorkCardLink to={`/${post.fields.slug}`}>
              <WorkImageWrapper
                image={
                  post.frontmatter.featuredImage.childImageSharp.gatsbyImageData
                }
                alt={post.frontmatter.title}
              />
              <Heading3>{post.frontmatter.title}</Heading3>
              <p>{post.frontmatter.summary}</p>
            </WorkCardLink>
          </WorkCard>
        ))}
      </WorkContent>
    </Main>
  )
}

export const pageQuery = graphql`
  query WorkIndex {
    allMdx {
      edges {
        node {
          id
          frontmatter {
            title
            date
            order
            tags
            summary
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default WorkIndex
